import {takeLatest, takeEvery} from 'redux-saga/effects'
import {
    AUTH,
    LOGIN,
    LOGOUT,
    LOGIN_ERROR,
    ADD_NEW_SITE,
    EDIT_SITE,
    DELETE_SITE,
    TRADE,
    ADD_WEBMASTER,
    TRADE_ONE,
    MANAGE_WEBMASTERS_OPEN,
    EDIT_WEBMASTER,
    DELETE_WEBMASTER
} from './constants/actiontypes'

import {auth, login, logout} from './sagas/auth'
import {login_error} from './sagas/errors'
import {add_site} from './sagas/add_site'
import {edit_site} from './sagas/edit_site'
import {delete_site} from './sagas/delete_site'
import {trade, trade_one} from './sagas/trade'
import {add_trader} from './sagas/add_trader'
import {fetch_trades} from './sagas/fetch_trades'
import {edit_webmaster} from './sagas/edit_webmaster'
import {delete_webmaster} from './sagas/delete_webmaster'


// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield [
        yield takeLatest(AUTH, auth),
        yield takeLatest(LOGIN, login),
        yield takeLatest(LOGOUT, logout),
        yield takeLatest(LOGIN_ERROR, login_error),
        yield takeLatest(ADD_NEW_SITE, add_site),
        yield takeLatest(EDIT_SITE, edit_site),
        yield takeLatest(DELETE_SITE, delete_site),
        yield takeLatest(TRADE, trade),
        yield takeEvery(TRADE_ONE, trade_one),
        yield takeLatest(ADD_WEBMASTER, add_trader),
        yield takeLatest(MANAGE_WEBMASTERS_OPEN, fetch_trades),
        yield takeLatest(EDIT_WEBMASTER, edit_webmaster),
        yield takeLatest(DELETE_WEBMASTER, delete_webmaster)
    ]
}
import React from 'react'
import TableBody from 'react-md/lib/DataTables/TableBody'
import {connect} from 'react-redux'
import TableRow from 'react-md/lib/DataTables/TableRow'
import TableColumn from 'react-md/lib/DataTables/TableColumn'
import EditDialogColumn from 'react-md/lib/DataTables/EditDialogColumn'

import Button from 'react-md/lib/Buttons/Button'
import {EDIT_WEBMASTER, DELETE_WEBMASTER_OPEN} from '../../constants/actiontypes'

const Body = ({ dispatch, trades, isSuperAdmin }) => (
    <TableBody>
        {trades.map(trade => <TableRow
            className="trade-row" key={trade.email + '-' + trade.name}
        >
            <TableColumn>{trade._id}</TableColumn>
            <EditDialogColumn
                title="Edit trader name" defaultValue={trade.name} large
                onOkClick={new_value => dispatch({type: EDIT_WEBMASTER, model: {...trade, name: new_value}})}
            />
            <EditDialogColumn
                title="Edit trader email" defaultValue={trade.email} large
                onOkClick={new_value => dispatch({type: EDIT_WEBMASTER, model: {...trade, email: new_value}})}
            />
            <EditDialogColumn
                title="Edit trader messenger" defaultValue={trade.messenger} large
                onOkClick={new_value => dispatch({type: EDIT_WEBMASTER, model: {...trade, messenger: new_value}})}
            />
            <EditDialogColumn className="trade-info"
                title="Edit trader info"
                defaultValue={trade.info}
                large
                onOkClick={new_value => dispatch({type: EDIT_WEBMASTER, model: {...trade, info: new_value}})}
            />
            <TableColumn className="md-table-checkbox"><Button icon onClick={() => dispatch({type: DELETE_WEBMASTER_OPEN, model: trade})}>delete</Button></TableColumn>
        </TableRow>)}
    </TableBody>
);

function mapStateToProps(state) {
    return {
        trades: state.traders.page_trades,
        isSuperAdmin: state.app.user.isSuperAdmin
    }
}

export default connect(mapStateToProps)(Body);
import React from 'react'
import Dialog from 'react-md/lib/Dialogs'
import {connect} from 'react-redux'
import {DELETE_WEBMASTER_CLOSE, DELETE_WEBMASTER} from '../../constants/actiontypes'

class DeleteSite extends React.PureComponent {
    _closeDialog = () => {
        const {dispatch} = this.props;
        dispatch({type: DELETE_WEBMASTER_CLOSE});
    };
    deleteWebmaster = (e) => {
        e.preventDefault();
        const {model, dispatch} = this.props;
        dispatch({type: DELETE_WEBMASTER, _id: model._id});
    };

    render() {
        const {model, visible} = this.props;
        const message = `Are you sure you want to delete webmaster ${model.name}?`;
        return (
            <Dialog
                id="delete-webmaster-dialog"
                visible={visible}
                aria-labelledby={'Delete webmaster'}
                onHide={this._closeDialog}
                modal
                actions={[
                    {
                        onClick: this._closeDialog,
                        secondary: true,
                        label: 'Cancel'
                    },
                    {
                        onClick: this.deleteWebmaster,
                        primary: true,
                        label: 'Delete'
                    }
                ]}
            >
                <h5 key="caption">
                    {message}
                </h5>
            </Dialog>
        )
    }
}
function mapStateToProps(state) {
    return {
        visible: state.traders.delete_visible,
        model: state.traders.delete_model
    }
}

export default connect(mapStateToProps)(DeleteSite);
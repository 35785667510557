export const AUTH = 'AUTH';
export const AUTH_OK = 'AUTH_OK';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_OK = 'LOGIN_OK';
export const LOGIN = 'LOGIN';
export const CHANGE_INVITE_CODE = 'CHANGE_INVITE_CODE';
export const LOGOUT = 'LOGOUT';
export const SHOW_SEARCH = 'SHOW_SEARCH';
export const CLOSE_SEARCH = 'CLOSE_SEARCH';
export const WINDOW_RESIZE = 'WINDOW_RESIZE';
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const ADD_NEW_SITE_OPEN = 'ADD_NEW_SITE_OPEN';
export const ADD_NEW_SITE_CLOSE = 'ADD_NEW_SITE_CLOSE';
export const ADD_NEW_SITE = 'ADD_NEW_SITE';
export const ADD_NEW_SITE_ERRORS = 'ADD_NEW_SITE_ERRORS';
export const ADD_NEW_SITE_OK = 'ADD_NEW_SITE_OK';
export const CHANGE_ADD_MODEL = 'CHANGE_ADD_MODEL';
export const EDIT_SITE_CLOSE = 'EDIT_SITE_CLOSE';
export const EDIT_SITE = 'EDIT_SITE';
export const CHANGE_EDIT_MODEL = 'CHANGE_EDIT_MODEL';
export const EDIT_SITE_OPEN = 'EDIT_SITE_OPEN';
export const EDIT_SITE_OK = 'EDIT_SITE_OK';
export const EDIT_SITE_ERRORS = 'EDIT_SITE_ERRORS';
export const DELETE_SITE = 'DELETE_SITE';
export const DELETE_SITE_OPEN = 'DELETE_SITE_OPEN';
export const DELETE_SITE_CLOSE = 'DELETE_SITE_CLOSE';
export const DELETE_SITE_OK = 'DELETE_SITE_OK';
export const SITE_ROW_TOGGLE = 'SITE_ROW_TOGGLE';
export const PREPARE_SITES = 'PREPARE_SITES';
export const TRADE_OPEN = 'TRADE_OPEN';
export const TRADE_CLOSE = 'TRADE_CLOSE';
export const TRADE = 'TRADE';
export const TRADE_ONE = 'TRADE_ONE';
export const TRADE_RESULTS = 'TRADE_RESULTS';
export const TRADER_DUMP_CHANGE = 'TRADER_DUMP_CHANGE';
export const TRADER_DUMP_ERROR = 'TRADER_DUMP_ERROR';
export const ADD_WEBMASTER_OPEN = 'ADD_WEBMASTER_OPEN';
export const ADD_WEBMASTER = 'ADD_WEBMASTER';
export const ADD_WEBMASTER_CLOSE = 'ADD_WEBMASTER_CLOSE';
export const ADD_WEBMASTER_OK = 'ADD_WEBMASTER_OK';
export const ADD_WEBMASTER_DONE_OPEN = 'ADD_WEBMASTER_DONE_OPEN';
export const ADD_WEBMASTER_DONE_CLOSE = 'ADD_WEBMASTER_DONE_CLOSE';
export const ADD_WEBMASTER_ERRORS = 'ADD_WEBMASTER_ERRORS';
export const MANAGE_WEBMASTERS_OPEN = 'MANAGE_WEBMASTERS_OPEN';
export const FETCH_WEBMASTERS_DONE = 'FETCH_WEBMASTERS_DONE';
export const MANAGE_WEBMASTERS_CLOSE = 'MANAGE_WEBMASTERS_CLOSE';
export const PREPARE_WEBMASTERS = 'PREPARE_WEBMASTERS';
export const EDIT_WEBMASTER = 'EDIT_WEBMASTER';
export const DELETE_WEBMASTER_OPEN = 'DELETE_WEBMASTER_OPEN';
export const DELETE_WEBMASTER_CLOSE = 'DELETE_WEBMASTER_CLOSE';
export const DELETE_WEBMASTER = 'DELETE_WEBMASTER';
export const DELETE_WEBMASTER_OK = 'DELETE_WEBMASTER_OK';
export const CHANGE_WEBMASTER_ADD_MODEL = 'CHANGE_WEBMASTER_ADD_MODEL';

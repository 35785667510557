import React from 'react'
import TableHeader from 'react-md/lib/DataTables/TableHeader'
import TableRow from 'react-md/lib/DataTables/TableRow'
import TableColumn from 'react-md/lib/DataTables/TableColumn'
import IconSeparator from 'react-md/lib/Helpers/IconSeparator'
import FontIcon from 'react-md/lib/FontIcons/FontIcon'
import {connect} from 'react-redux'
import {PREPARE_WEBMASTERS} from '../../constants/actiontypes'

const cn = 'md-table-column--adjusted';
const Header = ({sortBy, dispatch, sortOrder}) => {
    return (
        <TableHeader>
            <TableRow autoAdjust={false}>
                <TableColumn>Invite key</TableColumn>
                <TableColumn
                    sorted={sortBy === 'name' ? sortOrder : undefined}
                    onClick={() => dispatch({
                        type: PREPARE_WEBMASTERS,
                        sortBy: 'name',
                        sortOrder: sortBy === 'name' ? !sortOrder : true
                    })}
                    style={{cursor: 'pointer'}}
                >
                    {sortBy === 'name' ? 'Nickname' :
                        <IconSeparator label="Nickname" iconBefore>
                            <FontIcon>swap_vert</FontIcon>
                        </IconSeparator>}
                </TableColumn>
                <TableColumn
                    className={cn}
                    sorted={sortBy === 'email' ? sortOrder : undefined}
                    onClick={() => dispatch({
                        type: PREPARE_WEBMASTERS,
                        sortBy: 'email',
                        sortOrder: sortBy === 'email' ? !sortOrder : true
                    })}
                    style={{cursor: 'pointer'}}
                >{sortBy === 'email' ? 'Email' :
                    <IconSeparator label="Email" iconBefore>
                        <FontIcon>swap_vert</FontIcon>
                    </IconSeparator>}
                </TableColumn>
                <TableColumn
                    className={cn}
                    sorted={sortBy === 'messenger' ? sortOrder : undefined}
                    onClick={() => dispatch({
                        type: PREPARE_WEBMASTERS,
                        sortBy: 'messenger',
                        sortOrder: sortBy === 'messenger' ? !sortOrder : true
                    })}
                    style={{cursor: 'pointer'}}
                >{sortBy === 'messenger' ? 'Messenger' :
                    <IconSeparator label="Messenger" iconBefore>
                        <FontIcon>swap_vert</FontIcon>
                    </IconSeparator>}
                </TableColumn>
                <TableColumn className="md-table-column--grow">Info</TableColumn>
                <TableColumn className={cn}>Action</TableColumn>
            </TableRow>
        </TableHeader>
    )
};
function mapStateToProps(state) {
    return {
        sortBy: state.traders.sortBy,
        sortOrder: state.traders.sortOrder
    }
}
export default connect(mapStateToProps)(Header);
import React from 'react'
import {connect} from 'react-redux'
import DataTable from 'react-md/lib/DataTables'
import Card from 'react-md/lib/Cards/Card'
import Header from './Header'
import Body from './Body'
import TableCardHeader from 'react-md/lib/DataTables/TableCardHeader'
import TablePagination from 'react-md/lib/DataTables/TablePagination'
import SelectField from 'react-md/lib/SelectFields';
import Button from 'react-md/lib/Buttons/Button'
import AddDialog from './AddDialog'
import EditDialog from './EditDialog'
import DeleteDialog from './DeleteDialog'
import TradeDialog from './TradeDialog'
import TradeAddDialog from '../../components/Trades/AddDialog'
import TradeAddDoneDialog from '../../components/Trades/AddDoneDialog'
import Trades from '../../components/Trades'
import webmasters from '../../constants/webmasters'
import niches from '../../constants/niches'

import {
    ADD_NEW_SITE_OPEN, SITE_ROW_TOGGLE, EDIT_SITE_OPEN,
    DELETE_SITE_OPEN, PREPARE_SITES, TRADE_OPEN, ADD_WEBMASTER_OPEN
} from '../../constants/actiontypes'
import './_styles.scss'


class Main extends React.PureComponent {

    onRowToggle = (i, state) => {
        const {sites_selected, dispatch} = this.props;
        let selected = sites_selected.slice();
        if (i === 0) selected = selected.map(e => state);
        else selected[i-1] = state;
        dispatch({type: SITE_ROW_TOGGLE, sites_selected: selected});
    };

    render() {
        const {
            count, dispatch, isAdmin, sites_selected, page_sites, sites, perPage,
            webmaster_filter, niche_filter, mobile, trades_page
        } = this.props;
        if(trades_page) return <Trades/>;
        const actions = [];
        const selected_sites = [];
        for (let i = 0; i < sites_selected.length; i++) {
            if (sites_selected[i]) selected_sites.push(page_sites[i]);
        }
        if (mobile)
            actions.push(<Button
                key="trade" icon tooltipLabel="Trade with selected sites" tooltipPosition="left"
                onClick={() => dispatch({type: TRADE_OPEN, model: selected_sites})}
                tooltipDelay={300}>compare_arrows</Button>);
        else {
            actions.push(<Button
                key="trade" flat label="Setup trade"
                onClick={() => dispatch({type: TRADE_OPEN, model: selected_sites})}
            >compare_arrows</Button>);
        }
        if (isAdmin && count === 1) {
            const selected_site = page_sites[sites_selected.reduce((p, c, i) => {
                return (c === true ? i : p)
            }, false)];
            actions.push(<Button
                key="edit" icon
                tooltipLabel="Edit selected site"
                onClick={() => dispatch({type: EDIT_SITE_OPEN, model: selected_site})}
                tooltipDelay={300}>edit
            </Button>);
        }

        if (isAdmin) {
            actions.push(<Button
                key="delete" icon tooltipLabel="Delete selected site"
                onClick={() => dispatch({type: DELETE_SITE_OPEN, model: selected_sites})}
                tooltipDelay={300}>delete</Button>);
        }
        const webmasters_menu = webmasters.slice();
        webmasters_menu.unshift({label: 'All webmasters', value: ''});
        const niches_menu = niches.slice();
        niches_menu.unshift({label: 'All niches', value: ''});
        return (
            <Card tableCard>
                <TableCardHeader
                    title="Sites"
                    visible={count > 0}
                    contextualTitle={`${count} site${count > 1 ? 's' : ''} selected`}
                    actions={actions}
                >
                    <SelectField
                        style={{minWidth: 150}}
                        id="webmasters-select"
                        key="webmasters-select"
                        placeholder="Webmasters"
                        menuItems={webmasters_menu}
                        value={webmaster_filter}
                        onChange={webmaster_filter => dispatch({type: PREPARE_SITES, webmaster_filter})}
                        position={SelectField.Positions.BELOW}
                    />
                    <SelectField
                        style={{minWidth: 150}}
                        id="niches-select"
                        key="niches-select"
                        placeholder="Niches"
                        menuItems={niches_menu}
                        value={niche_filter}
                        onChange={niche_filter => dispatch({type: PREPARE_SITES, niche_filter})}
                        position={SelectField.Positions.BELOW}
                    />
                </TableCardHeader>
                <DataTable baseId="sites" onRowToggle={this.onRowToggle} defaultSelectedRows={sites_selected}>
                    <Header/>
                    <Body/>
                    <TablePagination
                        onPagination={(start, perPage) => dispatch({type: PREPARE_SITES, start, perPage})}
                        rows={sites.length}
                        defaultRowsPerPage={perPage}
                    />
                </DataTable>
                {isAdmin ? <Button key="add" floating secondary fixed className="add-site-button"
                                   tooltipLabel="Add new site" tooltipPosition="top"
                                   onClick={() => dispatch({type: ADD_NEW_SITE_OPEN})}>add</Button> : ''}
                {isAdmin ? <Button key="add_circle" floating primary fixed className="add-webmaster-button"
                                   tooltipLabel="Add new trader" tooltipPosition="top"
                                   onClick={() => dispatch({type: ADD_WEBMASTER_OPEN})}>add_circle_outline</Button> : ''}
                <AddDialog/>
                <EditDialog/>
                <DeleteDialog/>
                <TradeDialog/>
                <TradeAddDialog/>
                <TradeAddDoneDialog/>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        sites: state.app.filtered_sites,
        trades_page: state.traders.visible,
        mobile: state.app.mobile,
        page_sites: state.app.page_sites,
        count: state.app.sites_selected.filter(e => e).length,
        sites_selected: state.app.sites_selected,
        webmaster_filter: state.app.webmaster_filter,
        niche_filter: state.app.niche_filter,
        isAdmin: !!state.app.user.isAdmin,
        perPage: state.app.perPage
    }
}

export default connect(mapStateToProps)(Main);
import React from 'react'
import {connect} from 'react-redux'
import SnackbarComponent from 'react-md/lib/Snackbars'
import {REMOVE_TOAST} from '../../constants/actiontypes'

class Snackbar extends React.PureComponent {
    render() {
        return (
            <SnackbarComponent style={{zIndex: 1000}}
                onDismiss={() => this.props.dispatch({type: REMOVE_TOAST})}
                toasts={this.props.toasts}
                autohideTimeout={this.props.autohideTimeout || 2000}
                fab={this.props.fab}
            />
        )
    }
}
function mapStateToProps(state) {
    return {
        toasts: state.app.toasts,
        fab: state.app.fab_button
    }
}
export default connect(mapStateToProps)(Snackbar);

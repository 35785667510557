import {
    ADD_NEW_SITE_CLOSE,
    ADD_NEW_SITE_ERRORS,
    ADD_NEW_SITE_OK,
    ADD_NEW_SITE_OPEN,
    ADD_TOAST,
    AUTH,
    AUTH_ERROR,
    AUTH_OK,
    CHANGE_ADD_MODEL,
    CHANGE_EDIT_MODEL,
    CHANGE_INVITE_CODE,
    CLOSE_SEARCH,
    DELETE_SITE_CLOSE,
    DELETE_SITE_OK,
    DELETE_SITE_OPEN,
    EDIT_SITE_CLOSE,
    EDIT_SITE_ERRORS,
    EDIT_SITE_OK,
    EDIT_SITE_OPEN,
    LOGIN_ERROR,
    LOGIN_OK,
    PREPARE_SITES,
    REMOVE_TOAST,
    SHOW_SEARCH,
    SITE_ROW_TOGGLE,
    TRADE_CLOSE,
    TRADE_ONE,
    TRADE_OPEN,
    TRADE_RESULTS,
    TRADER_DUMP_CHANGE,
    TRADER_DUMP_ERROR,
    WINDOW_RESIZE
} from '../constants/actiontypes'

// The initial state of the App
const initialState = {
    authenticated: false,
    authenticating: true,
    invite_code: '',
    login: false,
    login_errors: [],
    site_filter: '',
    searching: false,
    mobile: false,
    tablet: false,
    sites: [],
    filtered_sites: [],
    page_sites: [],
    sites_selected: [],
    perPage: 10,
    sortBy: 'domain',
    sortOrder: true,
    sites_start_index: 0,
    add_site_visible: false,
    edit_site_visible: false,
    delete_site_visible: false,
    trader_dump_error: '',
    trade_visible: false,
    trade_model: [],
    trade_results: [],
    trader_dump: [],
    adding_queue: [],
    toasts: [],
    user: {},
    webmaster_filter: '',
    niche_filter: '',
    site_add_model: { niche: 'multiniche', api_type: 'ftt2' },
    site_add_errors: {},
    site_edit_model: {},
    site_edit_errors: {},
    site_delete_model: []
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH:
            return { ...state, authenticating: true, authenticated: false }
        case AUTH_OK:
            return { ...state, authenticated: true, authenticating: false, user: action.user }
        case AUTH_ERROR: {
            const invite_code = getCookie('invite_code') || ''
            return { ...state, authenticating: false, invite_code: invite_code }
        }
        case TRADE_RESULTS: {
            const trade_results = state.trade_results.slice()
            trade_results.push({ site: action.site, trade: action.trade, message: action.message })
            const adding_queue = state.adding_queue.slice()
            adding_queue.pop()
            return { ...state, trade_results: trade_results, adding_queue }
        }
        case TRADE_ONE: {
            const adding_queue = state.adding_queue.slice()
            adding_queue.push(true)
            return { ...state, adding_queue }
        }
        case TRADE_OPEN:
            return { ...state, trade_visible: true, trade_model: action.model }
        case TRADE_CLOSE:
            return { ...state, trade_visible: false, trade_model: [], trader_dump_error: '', trade_results: [] }
        case TRADER_DUMP_CHANGE:
            return {
                ...state, trader_dump_error: '', trader_dump: action.value.split('\n').map(line => {
                    let [url, title] = line.trim().split('|')
                    if (url !== undefined) url = url.trim()
                    if (title !== undefined) title = title.trim()
                    let domain = ''
                    if (url) {
                        try {
                            const site_url = new URL(url)
                            if (site_url.protocol && site_url.host) {
                                domain = site_url.host.replace(/^www\./i, '')
                            } else {
                                url = ''
                            }
                        } catch (err) {
                            url = ''
                        }
                    }
                    return { url: url || '', title: title || domain, domain }
                }).filter(e => e.url.length)
            }
        case TRADER_DUMP_ERROR:
            return { ...state, trader_dump_error: action.error }
        case ADD_NEW_SITE_OPEN: {
            return { ...state, add_site_visible: true }
        }
        case ADD_NEW_SITE_CLOSE: {
            return { ...state, add_site_visible: false, site_add_model: initialState.site_add_model }
        }
        case ADD_NEW_SITE_ERRORS: {
            return { ...state, site_add_errors: action.errors }
        }
        case ADD_NEW_SITE_OK: {
            const sites = state.sites.slice()
            const model = { ...state.site_add_model }
            model._id = action._id
            if (!model.webmaster) model.webmaster = state.user.name
            sites.push(model)
            return { ...state, sites }
        }
        case CHANGE_ADD_MODEL:
        case CHANGE_EDIT_MODEL: {
            const newState = { [action.field]: action.value }
            if (action.field === 'api_url') {
                try {
                    const api_url = new URL(action.value)
                    if (api_url.protocol && api_url.host) {
                        newState.url = api_url.protocol + '//' + api_url.host + '/'
                    }
                    if (api_url.hostname) {
                        newState.domain = api_url.hostname.split('.').slice(-2).join('.')
                        newState.name = newState.domain
                    }
                } catch (err) {
                }
            }
            if (action.type === CHANGE_ADD_MODEL) {
                return { ...state, site_add_model: { ...state.site_add_model, ...newState }, site_add_errors: {} }
            } else {
                return { ...state, site_edit_model: { ...state.site_edit_model, ...newState }, site_edit_errors: {} }
            }
        }
        case EDIT_SITE_ERRORS: {
            return { ...state, site_edit_errors: action.errors }
        }
        case EDIT_SITE_OPEN: {
            return { ...state, site_edit_model: action.model, site_edit_errors: {}, edit_site_visible: true }
        }
        case EDIT_SITE_CLOSE: {
            return { ...state, site_edit_model: {}, site_edit_errors: {}, edit_site_visible: false }
        }
        case EDIT_SITE_OK: {
            const sites = state.sites.slice()
            const site_index = sites.findIndex(e => state.site_edit_model._id === e._id)
            sites[site_index] = state.site_edit_model
            return { ...state, sites }
        }
        case DELETE_SITE_OPEN: {
            return { ...state, delete_site_visible: true, site_delete_model: action.model }
        }
        case DELETE_SITE_CLOSE: {
            return { ...state, delete_site_visible: false, site_delete_model: [] }
        }
        case DELETE_SITE_OK: {
            const deleted_ids = state.site_delete_model.map(e => e._id)
            const sites = state.sites.slice().filter(site => !deleted_ids.includes(site._id))
            return { ...state, sites }
        }
        case SHOW_SEARCH:
            return { ...state, searching: true }
        case CLOSE_SEARCH:
            return { ...state, searching: false }
        case WINDOW_RESIZE: {
            const new_state = {}
            if (action.width < 768) {
                new_state.mobile = true
                new_state.tablet = false
            }
            else if (action.width < 1025) {
                new_state.mobile = false
                new_state.tablet = true
            }
            else {
                new_state.mobile = false
                new_state.tablet = false
            }
            return { ...state, ...new_state }
        }
        case CHANGE_INVITE_CODE:
            return { ...state, invite_code: action.payload, login_errors: [] }
        case ADD_TOAST: {
            let toasts = state.toasts.slice()
            toasts.push(action.toast)
            return { ...state, toasts: toasts }
        }
        case REMOVE_TOAST: {
            const [, ...toasts] = state.toasts.slice()
            return { ...state, toasts: toasts }
        }
        case LOGIN_ERROR: {
            if (action.error instanceof Array) {
                return { ...state, login_errors: action.error }
            } else {
                return state
            }
        }
        case LOGIN_OK: {
            setCookie('invite_code', state.invite_code, 180)
            return { ...state, authenticated: true, login: false }
        }
        case SITE_ROW_TOGGLE: {
            return { ...state, sites_selected: action.sites_selected }
        }
        case PREPARE_SITES: {
            const sites = action.sites ? action.sites.slice() : state.sites.slice()
            const sortBy = action.sortBy !== undefined ? action.sortBy : state.sortBy
            const sortOrder = action.sortOrder !== undefined ? action.sortOrder : state.sortOrder
            const perPage = action.perPage || state.perPage
            const webmaster_filter = action.webmaster_filter !== undefined ? action.webmaster_filter : state.webmaster_filter
            const niche_filter = action.niche_filter !== undefined ? action.niche_filter : state.niche_filter
            const site_filter = action.site_filter || state.site_filter
            sites.sort((a, b) => {
                let ret = 0
                let aa = a[sortBy], bb = b[sortBy]
                if (typeof aa === 'string' && typeof bb === 'string') {
                    aa = aa.toLowerCase()
                    bb = bb.toLowerCase()
                }
                if (sortOrder) {
                    ret = aa > bb ? 1 : -1
                } else {
                    ret = aa < bb ? 1 : -1
                }
                return ret
            })
            const filtered_sites = sites.filter(site => {
                if (site_filter.length
                    && site.name.toLowerCase().indexOf(site_filter.toLowerCase()) === -1
                    && site.domain.toLowerCase().indexOf(site_filter.toLowerCase()) === -1
                ) {
                    return false
                }
                if (webmaster_filter.length && site.webmaster !== webmaster_filter) return false
                if (niche_filter.length && site.niche !== niche_filter) return false
                return true
            })
            let start = action.start !== undefined ? action.start : state.sites_start_index
            if (site_filter !== state.site_filter || webmaster_filter !== state.webmaster_filter
                || niche_filter !== state.niche_filter) {
                start = 0
            }
            const page_sites = filtered_sites.slice(start, perPage + start)
            const sites_selected = page_sites.map(e => false)
            const newState = {
                sites, filtered_sites, page_sites, sites_selected,
                sortBy, sortOrder, perPage, webmaster_filter, niche_filter
            }
            return { ...state, ...newState }
        }
        default:
            return state
    }
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function getCookie(cname) {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

import React from 'react'
import Button from 'react-md/lib/Buttons/Button'
import './_styles.scss'

const Footer = () => {
        //const {dispatch} = props;
        return (
            <footer className="md-grid app-footer">
                <section className="md-cell md-cell--3">
                    <h4 className="md-title">Sersh contact</h4>
                    <Button href={'mailto:to@g'.replace('g', 's') +'ersh.com'} flat secondary label={'to@sg'.replace('g', 'e')+'rsh.com'}>email</Button>
                    <Button href={'https://telegram.me/se' + '5ge888'.replace('5', 'r')} target="_blank" flat secondary label={'@serh'.replace('h', 'g') + 'e888'}>chat</Button>
                    <Button flat secondary label={'icq: 3604'.replace('4', '5') + '1319'}>chat</Button>
                </section>
                <section className="md-cell md-cell--3">
                    <h4 className="md-title">Shamanboo contact</h4>
                    <Button href={'mailto:sham' + 'anbgg@'.replace('gg', 'oo') + 'gmail.com'} flat secondary label={'shaman' + 'bkk@g'.replace('kk', 'oo') + 'mail.com'}>email</Button>
                    <Button href={'https://teleg'+'ram.me/Sga'.replace('ga', 'ha')+'manboo'} target="_blank" flat secondary label={'@Sha' + 'mjnboo'.replace('j', 'a')}>chat</Button>
                    <Button flat secondary label={'icq: 31' + '5574'.replace('55', '66')}>chat</Button>
                </section>
                <section className="md-cell md-cell--3">
                    <h4 className="md-title">Futuris contact</h4>
                    <Button href={'mailto:quantumf' + 'utfris@gm'.replace('f', 'u')+'ail.com'} flat secondary label={'quantumfut' + 'u3is@gmai'.replace('3', 'r') + 'l.com'}>email</Button>
                    <Button href={'https://teleg'+'ram.me/fata'.replace('a', 'u')+'risq'} target="_blank" flat secondary label={'@futu' + 'rjsq'.replace('j', 'i')}>chat</Button>
                </section>
                <section className="md-cell md-cell--3">
                    <h4 className="md-title">Karkaron contact</h4>
                    <Button href={'mailto:karka' + 'ron69@gm'.replace('69', '87')+'ail.com'} flat secondary label={'karka' + 'ron69@gmai'.replace('69', '87') + 'l.com'}>email</Button>
                    <Button href={'https://teleg'+'ram.me/kab'.replace('b', 'r')+'karon'} target="_blank" flat secondary label={'@kar' + 'kbron'.replace('b', 'a')}>chat</Button>
                </section>
                <p className="md-cell md-cell--12 copyright">
                    &copy; traffictrade.pro 2017
                </p>
            </footer>
        )
};

export default Footer;

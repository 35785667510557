import {put, select} from 'redux-saga/effects'
import 'whatwg-fetch'
//noinspection JSFileReferences

import {
    TRADE_RESULTS,
    TRADE_ONE,
    AUTH
} from '../constants/actiontypes'

export function *trade() {
    const model = yield select(state => state.app.trade_model);
    const dump = yield select(state => state.app.trader_dump);
    // Захуячим трейды со всеми сайтами трейдера
    for(let i=0; i<model.length; i++) {
        for(let j=0; j<dump.length; j++) {
            yield put({type: TRADE_ONE, site: model[i], trade: dump[j]});
        }
    }
}

export function *trade_one(action) {
    const data = {
        site: action.site._id,
        trade: action.trade
    };
    let message = 'OK';
    try {
        const response = yield fetch('/api/trade', {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: "include",
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            if (response.status === 401) {
                return yield put({type: AUTH});
            } else {
                //yield put({type: ADD_TOAST, toast: {text: 'Error doing request ' + response.statusText}});
                throw Error('Error doing request ' + response.statusText);
            }
        }
        const json = yield response.json();
        if (json instanceof Array) {
            message = 'Errors: ' + json.map(e => e.error).join(', ');
        } else {
            if(json.value) message = json.value;
        }
    } catch (err) {
        message = err.message;
    }
    yield put({type: TRADE_RESULTS, site: action.site, trade: action.trade, message});
}
import React from 'react'
import TableBody from 'react-md/lib/DataTables/TableBody'
import {connect} from 'react-redux'
import TableRow from 'react-md/lib/DataTables/TableRow'
import TableColumn from 'react-md/lib/DataTables/TableColumn'
import SelectFieldColumn from 'react-md/lib/DataTables/SelectFieldColumn'
import Button from 'react-md/lib/Buttons/Button'
import TrafficImage from './TrafficImage'
import webmasters from '../../constants/webmasters'
import {EDIT_SITE_OPEN, EDIT_SITE} from '../../constants/actiontypes'


const Body = ({ dispatch, sites, isAdmin, isSuperAdmin, selected }) => (
    <TableBody>
        {sites.map(site => <TableRow
            className="site-row" key={site.domain}
        >
            <TableColumn onDoubleClick={isAdmin ? () => dispatch({type: EDIT_SITE_OPEN, model: site}): undefined}>{site.name}</TableColumn>
            <TableColumn><Button flat href={site.url} label={site.domain} target="_blank"/></TableColumn>
            <TableColumn>{site.niche}</TableColumn>
            <TableColumn><TrafficImage domain={site.domain}/></TableColumn>
            {isSuperAdmin ?  <SelectFieldColumn menuItems={webmasters} value={site.webmaster} onChange={webmaster => {
                site.webmaster = webmaster; dispatch({type: EDIT_SITE, model: site});
            }} />
                : <TableColumn>{site.webmaster}</TableColumn>}
        </TableRow>)}
    </TableBody>
);

function mapStateToProps(state) {
    return {
        sites: state.app.page_sites,
        selected: state.app.sites_selected,
        isAdmin: state.app.user.isAdmin,
        isSuperAdmin: state.app.user.isSuperAdmin
    }
}

export default connect(mapStateToProps)(Body);
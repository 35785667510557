import React from 'react'
import {connect} from 'react-redux'
import DataTable from 'react-md/lib/DataTables'
import Card from 'react-md/lib/Cards/Card'
import Header from './Header'
import Body from './Body'
import TablePagination from 'react-md/lib/DataTables/TablePagination'
import Button from 'react-md/lib/Buttons/Button'
import TradeAddDialog from './AddDialog'
import TradeAddDoneDialog from './AddDoneDialog'
import CardTitle from "react-md/lib/Cards/CardTitle"
import LinearProgress from 'react-md/lib/Progress/LinearProgress'
import DeleteDialog from './DeleteDialog'
import './_styles.scss'

import {
    ADD_WEBMASTER_OPEN, PREPARE_WEBMASTERS
} from '../../constants/actiontypes'


class Trades extends React.PureComponent {

    render() {
        const {dispatch, perPage, trades, fetching} = this.props;
        if(fetching) return (
            <LinearProgress id="trades-progress"/>
        );
        return (
            <Card tableCard>
                <CardTitle title="Traders management"/>
                <DataTable baseId="traders" plain>
                    <Header/>
                    <Body/>
                    <TablePagination
                        onPagination={(start, perPage) => dispatch({type: PREPARE_WEBMASTERS, start, perPage})}
                        rows={trades.length}
                        defaultRowsPerPage={perPage}
                    />
                </DataTable>
                <Button key="add_circle" floating primary fixed className="add-webmaster-button"
                        tooltipLabel="Add new trader" tooltipPosition="top"
                        onClick={() => dispatch({type: ADD_WEBMASTER_OPEN})}>add_circle_outline</Button>
                <TradeAddDialog/>
                <TradeAddDoneDialog/>
                <DeleteDialog/>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        trades: state.traders.filtered_trades,
        fetching: state.traders.fetching,
        mobile: state.app.mobile,
        page_trades: state.traders.page_trades,
        perPage: state.app.perPage
    }
}

export default connect(mapStateToProps)(Trades);
import React from 'react'
import Dialog from 'react-md/lib/Dialogs'
import {connect} from 'react-redux'
import {DELETE_SITE_CLOSE, DELETE_SITE} from '../../constants/actiontypes'

class DeleteSite extends React.PureComponent {
    _closeDialog = () => {
        const {dispatch} = this.props;
        dispatch({type: DELETE_SITE_CLOSE});
    };
    deleteSite = (e) => {
        e.preventDefault();
        const {model, dispatch} = this.props;
        dispatch({type: DELETE_SITE, _id: model.map(e => e._id)});
    };

    render() {
        const {model, visible} = this.props;
        const message = `Are you sure you want to delete ${model.length} site${model.length > 1 ? 's': ''}?`;
        return (
            <Dialog
                id="delete-site-dialog"
                visible={visible}
                aria-labelledby={'Delete site' + model.length>1 ? 's': ''}
                onHide={this._closeDialog}
                modal
                actions={[
                    {
                        onClick: this._closeDialog,
                        secondary: true,
                        label: 'Cancel'
                    },
                    {
                        onClick: this.deleteSite,
                        primary: true,
                        label: 'Delete'
                    }
                ]}
            >
                <h5 key="caption">
                    {message}
                </h5>
            </Dialog>
        )
    }
}
function mapStateToProps(state) {
    return {
        visible: state.app.delete_site_visible,
        model: state.app.site_delete_model
    }
}

export default connect(mapStateToProps)(DeleteSite);
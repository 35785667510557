import {createStore, applyMiddleware, compose} from 'redux'
import Reducers from './reducers'
import createSagaMiddleware from 'redux-saga'
//import thunk from 'redux-thunk'
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {

    const middlewares = [
        sagaMiddleware
    ];

    //noinspection JSUnresolvedVariable
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        Reducers,
        {}, composeEnhancers(
            applyMiddleware(...middlewares)
        )
    );
    sagaMiddleware.run(rootSaga);
    return store;
}
import {put, select} from 'redux-saga/effects'
import 'whatwg-fetch'
//noinspection JSFileReferences

import {
    ADD_NEW_SITE_ERRORS,
    ADD_NEW_SITE_OK,
    ADD_TOAST,
    ADD_NEW_SITE_CLOSE,
    PREPARE_SITES,
    AUTH
} from '../constants/actiontypes'

export function *add_site() {
    const data = yield select(state => state.app.site_add_model);
    // Обновляем на сервере
    try {
        const response = yield fetch('/api/sites', {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: "include",
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            if (response.status === 401) {
                yield put({type: AUTH});
            } else {
                yield put({type: ADD_TOAST, toast: {text: 'Error adding site: ' + response.statusText}});
            }
            return;
        }
        const json = yield response.json();
        if (json instanceof Array) {
            const errors = {};
            json.forEach(e => {errors[e.name] = e.error});
            yield put({type: ADD_NEW_SITE_ERRORS, errors: errors});
            return;
        }
        yield put({type: ADD_NEW_SITE_OK, _id: json.value});
        yield put({type: PREPARE_SITES});
        yield put({type: ADD_TOAST, toast: {text: 'Site added successfully'}});
        yield put({type: ADD_NEW_SITE_CLOSE});
    } catch (err) {
        yield put({type: ADD_TOAST, toast: {text: 'Error adding site: ' + err.message}});
    }
}
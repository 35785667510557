import React from 'react'
import TableHeader from 'react-md/lib/DataTables/TableHeader'
import TableRow from 'react-md/lib/DataTables/TableRow'
import TableColumn from 'react-md/lib/DataTables/TableColumn'
import IconSeparator from 'react-md/lib/Helpers/IconSeparator'
import FontIcon from 'react-md/lib/FontIcons/FontIcon'
import {connect} from 'react-redux'
import {PREPARE_SITES} from '../../constants/actiontypes'

const cn = 'md-table-column--adjusted';
const Header = ({sortBy, dispatch, sortOrder}) => {
    return (
        <TableHeader>
            <TableRow autoAdjust={false}>
                <TableColumn
                    className="md-table-column--grow"
                    sorted={sortBy === 'name' ? sortOrder : undefined}
                    onClick={() => dispatch({
                        type: PREPARE_SITES,
                        sortBy: 'name',
                        sortOrder: sortBy === 'name' ? !sortOrder : true
                    })}
                    style={{cursor: 'pointer'}}
                >
                    {sortBy === 'name' ? 'Site name' :
                        <IconSeparator label="Site name" iconBefore>
                            <FontIcon>swap_vert</FontIcon>
                        </IconSeparator>}
                </TableColumn>
                <TableColumn
                    className={cn}
                    sorted={sortBy === 'domain' ? sortOrder : undefined}
                    onClick={() => dispatch({
                        type: PREPARE_SITES,
                        sortBy: 'domain',
                        sortOrder: sortBy === 'domain' ? !sortOrder : true
                    })}
                    style={{cursor: 'pointer'}}
                >{sortBy === 'domain' ? 'Domain' :
                    <IconSeparator label="Domain" iconBefore>
                        <FontIcon>swap_vert</FontIcon>
                    </IconSeparator>}
                </TableColumn>
                <TableColumn
                    className={cn}
                    sorted={sortBy === 'niche' ? sortOrder : undefined}
                    onClick={() => dispatch({
                        type: PREPARE_SITES,
                        sortBy: 'niche',
                        sortOrder: sortBy === 'niche' ? !sortOrder : true
                    })}
                    style={{cursor: 'pointer'}}
                >{sortBy === 'niche' ? 'Niche' :
                    <IconSeparator label="Niche" iconBefore>
                        <FontIcon>swap_vert</FontIcon>
                    </IconSeparator>}
                </TableColumn>
                <TableColumn className={cn}>Traffic</TableColumn>
                <TableColumn
                    className={cn}
                    sorted={sortBy === 'webmaster' ? sortOrder : undefined}
                    onClick={() => dispatch({
                        type: PREPARE_SITES,
                        sortBy: 'webmaster',
                        sortOrder: sortBy === 'webmaster' ? !sortOrder : true
                    })}
                    style={{cursor: 'pointer'}}
                >{sortBy === 'webmaster' ? 'Webmaster' :
                    <IconSeparator label="Webmaster" iconBefore>
                        <FontIcon>swap_vert</FontIcon>
                    </IconSeparator>}
                </TableColumn>
            </TableRow>
        </TableHeader>
    )
};
function mapStateToProps(state) {
    return {
        isAdmin: !!state.app.user.isAdmin,
        sortBy: state.app.sortBy,
        sortOrder: state.app.sortOrder
    }
}
export default connect(mapStateToProps)(Header);
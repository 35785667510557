import React from 'react'
import {connect} from 'react-redux'

import Dialog from 'react-md/lib/Dialogs'
import Toolbar from 'react-md/lib/Toolbars/Toolbar'
import Button from 'react-md/lib/Buttons/Button'

import {EDIT_SITE_CLOSE, EDIT_SITE, CHANGE_EDIT_MODEL} from '../../constants/actiontypes'
import TextField from "react-md/lib/TextFields/TextField"
import SelectField from 'react-md/lib/SelectFields/SelectField'
import niches from '../../constants/niches'
import api_types from '../../constants/api_types'
import webmasters from '../../constants/webmasters'

class EditDialog extends React.PureComponent {
    onDialogShow = () => {
        this.refs.api_url.focus();
    };

    render() {
        const {visible, dispatch, mobile, model, errors, isSuperAdmin, webmaster_name} = this.props;
        return (
            <Dialog visible={visible} id="edit-site"
                    dialogClassName="edit-site"
                    modal
                    fullPage={mobile}
                    aria-labelledby="Edit site"
                    onHide={() => dispatch({type: EDIT_SITE_CLOSE})}
                    onShow={this.onDialogShow}
            >
                <Toolbar title="Edit site" fixed colored
                         nav={<Button onClick={() => dispatch({type: EDIT_SITE_CLOSE})} icon children="close"/>}
                         actions={<Button onClick={() => dispatch({type: EDIT_SITE})} icon secondary
                                          children="done"/>}
                />
                <form className="md-toolbar-relative md-grid md-text-container"
                      onSubmit={(e) => {
                          e.preventDefault();
                          dispatch({type: EDIT_SITE})
                      }}>
                    <SelectField
                        id='api_type'
                        name='api_type'
                        menuItems={api_types}
                        onChange={v => dispatch({type: CHANGE_EDIT_MODEL, field: 'api_type', value: v})}
                        className='md-cell md-cell--12'
                        value={model.api_type}
                        label='Api Type'
                    />
                    <TextField type="url" id="api_url" ref="api_url" name="api_url" label="Site API URL"
                               className="md-cell md-cell--6" value={model.api_url || ''}
                               onChange={v => dispatch({type: CHANGE_EDIT_MODEL, field: 'api_url', value: v})}
                               errorText={errors.api_url} error={!!errors.api_url}
                    />
                    <TextField id="api_secret" name="api_secret" label="Site API Secret Key"
                               className="md-cell md-cell--6" value={model.api_secret || ''}
                               onChange={v => dispatch({type: CHANGE_EDIT_MODEL, field: 'api_secret', value: v})}
                               errorText={errors.api_secret} error={!!errors.api_secret}
                    />
                    <TextField id='url' name='url' label="Site URL"
                               className="md-cell md-cell--6" value={model.url || ''}
                               onChange={v => dispatch({type: CHANGE_EDIT_MODEL, field: 'url', value: v})}
                               errorText={errors.url} error={!!errors.url}
                    />
                    <TextField id='name' name='name' label="Site name"
                               className="md-cell md-cell--6" value={model.name || ''}
                               onChange={v => dispatch({type: CHANGE_EDIT_MODEL, field: 'name', value: v})}
                               errorText={errors.name} error={!!errors.name}
                    />
                    <TextField id='domain' name='domain' label="Site domain"
                               className="md-cell md-cell--6" value={model.domain || ''}
                               onChange={v => dispatch({type: CHANGE_EDIT_MODEL, field: 'domain', value: v})}
                               errorText={errors.domain} error={!!errors.domain}
                    />
                    <SelectField id="niche" name="niche" label="Site niche"
                                 menuItems={niches} value={model.niche}
                                 className="md-cell md-cell--6"
                                 onChange={v => dispatch({type: CHANGE_EDIT_MODEL, field: 'niche', value: v})}
                                 errorText={errors.niche} error={!!errors.niche}
                    />
                    {isSuperAdmin ? <SelectField id="webmaster"
                                                 name="webmaster" label="Webmaster"
                                                 menuItems={webmasters} value={model.webmaster || webmaster_name}
                                                 className="md-cell md-cell--6"
                                                 onChange={v => dispatch({
                                                     type: CHANGE_EDIT_MODEL,
                                                     field: 'webmaster',
                                                     value: v
                                                 })}
                                                 errorText={errors.webmaster} error={!!errors.webmaster}
                    /> : ''}
                </form>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.app.edit_site_visible,
    mobile: state.app.mobile,
    model: state.app.site_edit_model,
    errors: state.app.site_edit_errors,
    isSuperAdmin: state.app.user.isSuperAdmin,
    webmaster_name: state.app.user.name
});

export default connect(mapStateToProps)(EditDialog);
import React from 'react'
import { connect } from 'react-redux'

import Dialog from 'react-md/lib/Dialogs'
import Toolbar from 'react-md/lib/Toolbars/Toolbar'
import Button from 'react-md/lib/Buttons/Button'

import { ADD_WEBMASTER_CLOSE, ADD_WEBMASTER, CHANGE_WEBMASTER_ADD_MODEL } from '../../constants/actiontypes'
import TextField from 'react-md/lib/TextFields/TextField'

class AddDialog extends React.PureComponent {
    onDialogShow = () => {
        this.refs.name.focus()
    }

    render() {
        const { visible, dispatch, mobile, model, errors } = this.props
        return (
            <Dialog visible={visible} id="add-new-trader"
                    dialogClassName="add-new-trader"
                    modal
                    fullPage={mobile}
                    aria-labelledby="Add new trader"
                    onHide={() => dispatch({ type: ADD_WEBMASTER_CLOSE })}
                    onShow={this.onDialogShow}
            >
                <Toolbar title="Add new trader" fixed colored
                         nav={<Button onClick={() => dispatch({ type: ADD_WEBMASTER_CLOSE })} icon children="close"/>}
                         actions={<Button onClick={() => dispatch({ type: ADD_WEBMASTER })} icon secondary
                                          children="done"/>}
                />
                <form className="md-toolbar-relative md-grid md-text-container"
                      onSubmit={(e) => {
                          e.preventDefault()
                          dispatch({ type: ADD_WEBMASTER })
                      }}>
                    <TextField id="name" ref="name" name="api_url" label="Webmaster Nickname"
                               className="md-cell md-cell--6" value={model.name || ''}
                               required
                               onChange={v => dispatch({ type: CHANGE_WEBMASTER_ADD_MODEL, field: 'name', value: v })}
                               errorText={errors.name} error={!!errors.name}
                    />
                    <TextField id="messenger" name="messenger" label="Webmaster Messenger"
                               required
                               className="md-cell md-cell--6" value={model.messenger || ''}
                               onChange={v => dispatch({
                                   type: CHANGE_WEBMASTER_ADD_MODEL,
                                   field: 'messenger',
                                   value: v
                               })}
                               errorText={errors.messenger} error={!!errors.messenger}
                    />
                    <TextField id='email' type="email" name='email' label="Webmaster Email"
                               required
                               className="md-cell md-cell--6" value={model.email || ''}
                               onChange={v => dispatch({ type: CHANGE_WEBMASTER_ADD_MODEL, field: 'email', value: v })}
                               errorText={errors.email} error={!!errors.email}
                    />
                    <TextField id='info' name='info' label="Additional Info"
                               className="md-cell md-cell--12" value={model.info || ''}
                               onChange={v => dispatch({ type: CHANGE_WEBMASTER_ADD_MODEL, field: 'info', value: v })}
                               errorText={errors.info} error={!!errors.info}
                    />
                </form>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    visible: state.traders.add_webmaster_visible,
    mobile: state.app.mobile,
    model: state.traders.trade_add_model,
    errors: state.traders.trade_add_errors,
    webmaster_name: state.app.user.name
})

export default connect(mapStateToProps)(AddDialog)
import React from 'react'
import Dialog from 'react-md/lib/Dialogs'
import {connect} from 'react-redux'
import {ADD_WEBMASTER_DONE_CLOSE} from '../../constants/actiontypes'
import TextField from "react-md/lib/TextFields/TextField";

class AddDoneDialog extends React.PureComponent {
    _closeDialog = () => {
        const {dispatch} = this.props;
        dispatch({type: ADD_WEBMASTER_DONE_CLOSE});
    };
    _noop = () => null;
    _onShow = () => {
        this.refs.invite.getField().select();
    };
    render() {
        const {model, visible} = this.props;
        return (
            <Dialog
                id="add-done-dialog"
                visible={visible}
                aria-labelledby={'Trader added'}
                onHide={this._closeDialog}
                onShow={this._onShow}
                modal
                actions={[
                    {
                        onClick: this._closeDialog,
                        secondary: true,
                        label: 'Close'
                    }
                ]}
            >
                <TextField id="invite_code" label="Invite code for webmaster" value={model.invite_code} onChange={this._noop}
                           helpText="Press Ctrl-C to copy"
                           ref="invite"/>
            </Dialog>
        )
    }
}
function mapStateToProps(state) {
    return {
        visible: state.traders.add_done_visible,
        model: state.traders.add_done_model
    }
}

export default connect(mapStateToProps)(AddDoneDialog);
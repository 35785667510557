import {put} from 'redux-saga/effects'
import 'whatwg-fetch'
//noinspection JSFileReferences

import {
    ADD_TOAST,
    AUTH,
    DELETE_SITE_CLOSE,
    PREPARE_SITES,
    DELETE_SITE_OK
} from '../constants/actiontypes'

export function *delete_site(action) {
    try {
        const response = yield fetch('/api/sites', {
            method: 'DELETE',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: "include",
            body: JSON.stringify({_id: action._id})
        });
        if (!response.ok) {
            if (response.status === 401) {
                yield put({type: AUTH});
            } else {
                yield put({type: ADD_TOAST, toast: {text: 'Error deleting site: ' + response.statusText}});
            }
            return;
        }
        const json = yield response.json();
        if (json instanceof Array) {
            for(let i=0; i< json.length; i++) {
                yield put({type: ADD_TOAST, toast: {text: 'Error deleting site: ' + json[i].error}});
            }
            return;
        }
        yield put({type: DELETE_SITE_OK});
        yield put({type: PREPARE_SITES});
        yield put({type: DELETE_SITE_CLOSE});
        yield put({type: ADD_TOAST, toast: {text: 'Successfully deleted'}});
    } catch (err) {
        yield put({type: ADD_TOAST, toast: {text: 'Error deleting site: ' + err.message}});
    }
}

import {
    ADD_WEBMASTER_CLOSE,
    ADD_WEBMASTER_OPEN,
    CHANGE_WEBMASTER_ADD_MODEL,
    ADD_WEBMASTER_DONE_CLOSE,
    ADD_WEBMASTER_DONE_OPEN,
    ADD_WEBMASTER_ERRORS,
    MANAGE_WEBMASTERS_OPEN,
    MANAGE_WEBMASTERS_CLOSE,
    FETCH_WEBMASTERS_DONE,
    PREPARE_WEBMASTERS,
    DELETE_WEBMASTER_OK,
    DELETE_WEBMASTER_CLOSE,
    DELETE_WEBMASTER_OPEN
} from '../constants/actiontypes'

const initialState = {
    add_webmaster_visible: false,
    visible: false,
    delete_visible: false,
    delete_model: {},
    trades: [],
    filtered_trades: [],
    sortBy: 'name',
    sortOrder: true,
    page_trades: [],
    trade_add_model: {},
    trade_add_errors: {},
    add_done_model: {},
    add_done_visible: false,
    filter: '',
    fetching: false
};

export default function tradersReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_WEBMASTER_CLOSE:
            return {...state, add_webmaster_visible: false, trade_add_model: {}};
        case ADD_WEBMASTER_OPEN:
            return {...state, add_webmaster_visible: true};
        case CHANGE_WEBMASTER_ADD_MODEL: {
            return {...state, trade_add_model: {...state.trade_add_model, [action.field]: action.value}};
        }
        case MANAGE_WEBMASTERS_OPEN:
            return {...state, visible: true, fetching: true};
        case FETCH_WEBMASTERS_DONE:
            return {...state, fetching: false};
        case MANAGE_WEBMASTERS_CLOSE:
            return {...state, visible: false};
        case ADD_WEBMASTER_ERRORS:
            return {...state, trade_add_errors: action.errors};
        case ADD_WEBMASTER_DONE_CLOSE:
            return {...state, add_done_model: {}, add_done_visible: false};
        case ADD_WEBMASTER_DONE_OPEN:
            return {...state, add_done_model: action.model, add_done_visible: true};
        case DELETE_WEBMASTER_OPEN:
            return {...state, delete_visible: true, delete_model: action.model};
        case DELETE_WEBMASTER_CLOSE:
            return {...state, delete_visible: false, delete_model: {}};
        case DELETE_WEBMASTER_OK: {
            const trades = state.trades.slice();
            return {...state, trades: trades.filter(e => e._id !== action._id)};
        }
        case PREPARE_WEBMASTERS: {
            const trades = action.trades ? action.trades : state.trades.slice();
            const sortBy = action.sortBy !== undefined ? action.sortBy : state.sortBy;
            const sortOrder = action.sortOrder !== undefined ? action.sortOrder : state.sortOrder;
            const perPage = action.perPage || state.perPage;
            const filter = action.filter !== undefined ? action.filter : state.filter;
            trades.sort((a, b) => {
                let ret = 0;
                let aa = a[sortBy], bb = b[sortBy];
                if (typeof aa === 'string' && typeof bb === 'string') {
                    aa = aa.toLowerCase();
                    bb = bb.toLowerCase();
                }
                if (sortOrder)
                    ret = aa > bb ? 1 : -1;
                else
                    ret = aa < bb ? 1 : -1;
                return ret;
            });
            const filtered_trades = trades.filter(trade => {
                return !(filter.length
                && trade.name.toLowerCase().indexOf(filter.toLowerCase()) === -1
                && trade.email.toLowerCase().indexOf(filter.toLowerCase()) === -1
                && trade.messenger.toLowerCase().indexOf(filter.toLowerCase()) === -1);
            });
            const page_trades = filtered_trades.slice(0, perPage);
            const newState = {
                trades, filtered_trades, page_trades,
                sortBy, sortOrder, perPage, filter
            };
            return {...state, ...newState};
        }
        default:
            return state;
    }
}

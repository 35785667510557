import React, {Component} from 'react'
import {connect} from 'react-redux'
import LinearProgress from 'react-md/lib/Progress/LinearProgress'
import SnackBar from '../../components/Snackbar'
import Toolbar from '../../components/Toolbar'
import Login from '../../components/Login'
import Footer from '../../components/Footer'
import Main from '../../components/Main'
import {AUTH, WINDOW_RESIZE} from '../../constants/actiontypes'
import ResizeObserver from 'resize-observer-polyfill'

class App extends Component {
    constructor(props) {
        super(props);
        const self = this;
        if (self.props.authenticating) {
            this.props.dispatch({type: AUTH});
        }
    }

    updateDimensions = () => {
        const {dispatch} = this.props;
        dispatch({type: WINDOW_RESIZE, width: window.innerWidth, height: window.innerHeight});
        const watchResize = () => {
            const window_height = window.innerHeight;
            const app_height = document.getElementsByClassName('app')[0].offsetHeight;
            let footer_margin = document.getElementsByClassName('app-footer')[0].style.marginTop || 0;
            footer_margin = parseInt(footer_margin, 10);
            if (app_height - footer_margin < window_height) {
                document.getElementsByClassName('app-footer')[0].style.marginTop = (window_height - app_height + footer_margin) + 'px';
            } else {
                document.getElementsByClassName('app-footer')[0].style.marginTop = 0;
            }
        };
        const ro = new ResizeObserver(watchResize);
        ro.observe(document.getElementsByClassName('app-body')[0]);
        ro.observe(document.getElementsByClassName('app-header')[0]);
        ro.observe(document.getElementsByClassName('app-footer')[0]);
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        let section;
        if (this.props.authenticating) {
            section = (
                <LinearProgress id="auth-progress" key="auth-progress"/>
            );
        } else if (!this.props.authenticated) {
            section = (
                <Login/>
            );
        } else {
            section = (
                <Main/>
            );
        }
        return (
            <div className="app">
                <Toolbar/>
                <section className="app-body">
                    {section}
                </section>
                <Footer/>
                <SnackBar/>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        authenticating: state.app.authenticating,
        authenticated: state.app.authenticated,
        login: state.app.login
    }
}
export default connect(mapStateToProps)(App);

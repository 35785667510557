import React from 'react'
import {connect} from 'react-redux'
import {LOGIN, CHANGE_INVITE_CODE} from '../../constants/actiontypes'
import TextField from "react-md/lib/TextFields/TextField"
import Toolbar from 'react-md/lib/Toolbars/Toolbar'
import Button from 'react-md/lib/Buttons/Button'

import './_styles.scss'

const Login = (props) => {
    const {dispatch, invite_code, errors, error} = props;
    return (
        <Toolbar component="form" onSubmit={(e) => {
            e.preventDefault();
            dispatch({type: LOGIN, invite_code: invite_code})
        }} className="md-grid login-form">
            <TextField id="invite_code" name="invite_code" value={invite_code}
                       onChange={value => dispatch({type: CHANGE_INVITE_CODE, payload: value})}
                       error={error}
                       errorText={errors.reduce((p, c) => {
                           return c.name === '_id' ? c.error : p
                       }, '')}
                       className="md-cell md-cell--8" label="Enter your Invite code" size={24}/>
            <div className="md-cell md-cell--4"><Button id="login_submit" type="submit"
                                                        name="login_submit" raised primary label="Submit"/></div>
            <p className="md-cell md-cell--12">If you don't have invite code, get one by contacting webmasters directly
                using contact information on page bottom</p>
        </Toolbar>
    )
};

function mapStateToProps(state) {
    return {
        invite_code: state.app.invite_code,
        errors: state.app.login_errors,
        error: !!state.app.login_errors.length
    }
}

export default connect(mapStateToProps)(Login);
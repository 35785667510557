import React from 'react'
import {connect} from 'react-redux'
import ToolbarComponent from 'react-md/lib/Toolbars/Toolbar'
import Button from 'react-md/lib/Buttons/Button'

import {
    CLOSE_SEARCH, SHOW_SEARCH, LOGOUT, PREPARE_SITES, MANAGE_WEBMASTERS_OPEN, MANAGE_WEBMASTERS_CLOSE
}
    from '../../constants/actiontypes'
import TextField from "react-md/lib/TextFields/TextField";
import './_styles.scss'

const Toolbar = (props) => {
    const {searching, dispatch, search_value, mobile, authenticated, isAdmin, trades_page} = props;
    let nav, title, actions, children;
    const MenuLogout = <Button key="logout-btn"
                               id="logout-btn"
                               icon
                               className="md-btn--toolbar"
                               onClick={() => dispatch({type: LOGOUT})}
    >power_settings_new</Button>;
    let ManageButton;
    if (!trades_page)
        ManageButton = <Button key="manage-webmasters-button" onClick={() => dispatch({type: MANAGE_WEBMASTERS_OPEN})}
                               icon className="md-btn--toolbar">swap_horiz</Button>;
    else
        ManageButton = <Button key="manage-sites-button" onClick={() => dispatch({type: MANAGE_WEBMASTERS_CLOSE})}
                               icon className="md-btn--toolbar">dashboard</Button>;

    if (mobile) {
        if (searching) {
            nav = <Button onClick={() => {
                dispatch({type: CLOSE_SEARCH});
                dispatch({type: PREPARE_SITES, site_filter: ''})
            }} icon>arrow_back</Button>;
            //actions = <Button onClick={() => dispatch({type: RESET_SEARCH})} icon>close</Button>;
            if (authenticated)
                children = [
                    <TextField
                        id="autocomplete"
                        placeholder="Search for sites"
                        block
                        paddedBlock={false}
                        value={search_value}
                        onChange={value => dispatch({type: PREPARE_SITES, site_filter: value})}
                        className="md-title--toolbar"
                        inputClassName="md-text-field--toolbar"
                        key="search-site"
                    />];
            if (isAdmin) children.push(ManageButton);
            children.push(MenuLogout);
        } else {
            title = 'Traffic Trade Pro';
            if (authenticated)
                actions = [<Button onClick={() => dispatch({type: SHOW_SEARCH})} icon key="search-btn">search</Button>];
            if (isAdmin) actions.push(ManageButton);
            actions.push(MenuLogout);
        }
    } else {
        title = 'Traffic Trade Pro - trade adult traffic';
        if (authenticated) {
            children = [<TextField id="search" key="search-field" name="search" className="search-toolbar"
                                   label="Search sites" value={search_value}
                                   onChange={value => dispatch({type: PREPARE_SITES, site_filter: value})}/>];
            if (isAdmin) children.push(ManageButton);
            children.push(MenuLogout);
        }
    }
    return (
        <ToolbarComponent className="app-header"
                          colored
                          nav={nav}
                          actions={actions}
                          title={title}
        >
            {children}
        </ToolbarComponent>
    );
};

function mapStateToProps(state) {
    return {
        searching: state.app.searching,
        trades_page: state.traders.visible,
        search_value: state.app.search_value,
        mobile: state.app.mobile,
        authenticated: state.app.authenticated,
        isAdmin: state.app.user.isAdmin
    }
}

export default connect(mapStateToProps)(Toolbar);

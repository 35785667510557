import React from 'react'
import Button from 'react-md/lib/Buttons/Button'

const TrafficImage = ({domain}) => {
    const tooltipLabel = <img
        alt="alexa traffic stats"
        src={'http://traffic.alexa.com/graph?o=lt&y=t&b=ffffff&n=666666&f=999999&p=4e8cff&r=1y&t=2&z=30&c=1&h=150&w=340&u=' + domain}
        height={150} width={340}/>;
    const buttonLabel = <img
        alt="alexa traffic stats"
        src={'http://traffic.alexa.com/graph?o=lt&y=t&b=ffffff&n=666666&f=999999&p=4e8cff&r=1y&t=2&z=30&c=1&h=150&w=340&u=' + domain}
        height={45} width={120}/>;
    return <Button
        className="alexa-button" flat label={buttonLabel} target='_blank'
        href={"http://www.alexa.com/siteinfo/" + domain} tooltipLabel={tooltipLabel} tooltipPosition='left'
        tooltipDelay={200}
        tooltipContainerClassName="alexa-info-big"/>
};

export default TrafficImage
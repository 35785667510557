import {put} from 'redux-saga/effects'
import 'whatwg-fetch'
//noinspection JSFileReferences

import {
    ADD_TOAST,
    AUTH
} from '../constants/actiontypes'

export function *edit_webmaster(action) {
    const data = action.model;
    // Обновляем на сервере
    try {
        const response = yield fetch('/api/users', {
            method: 'PUT',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: "include",
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            if (response.status === 401) {
                yield put({type: AUTH});
            } else {
                yield put({type: ADD_TOAST, toast: {text: 'Error updating trader: ' + response.statusText}});
            }
            return;
        }
        const json = yield response.json();
        if (json instanceof Array) {
            for (let i = 0; i < json.length; i++) {
                yield put({type: ADD_TOAST, toast: {text: 'Error updating trader: ' + json[i].error}});
            }
            return;
        }
        //yield put({type: PREPARE_WEBMASTERS});
        yield put({type: ADD_TOAST, toast: {text: 'Trade updated successfully'}});
    } catch (err) {
        yield put({type: ADD_TOAST, toast: {text: 'Error updating site: ' + err.message}});
    }
}
import {put, select} from 'redux-saga/effects'
import 'whatwg-fetch'
//noinspection JSFileReferences

import {
    ADD_WEBMASTER_ERRORS,
    ADD_WEBMASTER_OK,
    ADD_TOAST,
    ADD_WEBMASTER_CLOSE,
    PREPARE_WEBMASTERS,
    MANAGE_WEBMASTERS_OPEN,
    ADD_WEBMASTER_DONE_OPEN,
    AUTH
} from '../constants/actiontypes'

export function *add_trader() {
    const data = yield select(state => state.traders.trade_add_model);
    // Обновляем на сервере
    try {
        const response = yield fetch('/api/users', {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: "include",
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            if (response.status === 401) {
                yield put({type: AUTH});
            } else {
                yield put({type: ADD_TOAST, toast: {text: 'Error adding webmaster: ' + response.statusText}});
            }
            return;
        }
        const json = yield response.json();
        if (json instanceof Array) {
            const errors = {};
            json.forEach(e => {errors[e.name] = e.error});
            yield put({type: ADD_WEBMASTER_ERRORS, errors: errors});
            return;
        }
        const visible = yield select(state => state.traders.visible);
        if(visible) {
            yield put({type: MANAGE_WEBMASTERS_OPEN});
        }
        yield put({type: ADD_WEBMASTER_OK, _id: json.value});
        yield put({type: PREPARE_WEBMASTERS});
        yield put({type: ADD_TOAST, toast: {text: 'Webmaster added successfully'}});
        yield put({type: ADD_WEBMASTER_CLOSE});
        yield put({type: ADD_WEBMASTER_DONE_OPEN, model: {invite_code: json.value}});
    } catch (err) {
        yield put({type: ADD_TOAST, toast: {text: 'Error adding webmaster: ' + err.message}});
    }
}
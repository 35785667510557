import React from 'react'
import Dialog from 'react-md/lib/Dialogs'
import {connect} from 'react-redux'
import {TRADE_CLOSE, TRADE, TRADER_DUMP_CHANGE, TRADER_DUMP_ERROR} from '../../constants/actiontypes'
import Button from 'react-md/lib/Buttons/Button'
import TextField from 'react-md/lib/TextFields/TextField'
import CircularProgress from 'react-md/lib/Progress/CircularProgress'
import Toolbar from 'react-md/lib/Toolbars/Toolbar'


class TradeDialog extends React.PureComponent {
    _onShow = () => {
        this.refs.selected.getField().select();
    };

    _closeDialog = () => {
        const {dispatch} = this.props;
        dispatch({type: TRADE_CLOSE});
    };

    doTrade = (e) => {
        e.preventDefault();
        const {dispatch, trader_dump} = this.props;
        if(!trader_dump.length) {
            dispatch({type: TRADER_DUMP_ERROR, error: 'No dump provided or wrong dump format'});
        } else {
            dispatch({type: TRADE});
        }
    };

    render() {
        const {model, visible, dispatch, trade_results, have_result, adding, trader_dump_error} = this.props;
        const dump = model.reduce((dump, site) => {
            return dump + site.url + '|' + site.name + "\r\n";
        }, '');
        const results = trade_results.reduce((acc, cur) => {
            return acc + cur.site.domain + ' vs ' + cur.trade.domain + ': ' + cur.message + "\r\n";
        }, '');
        return (
            <Dialog
                id="trade-add-dialog"
                dialogClassName="add-trade"
                visible={visible}
                aria-labelledby={'Add trade' + model.length > 1 ? 's' : ''}
                onHide={this._closeDialog}
                onShow={this._onShow}
                modal
                fullPage={true}
            >
                <Toolbar title={'Add trade' + (model.length > 1 ? 's' : '')} fixed colored
                         nav={<Button onClick={() => dispatch({type: TRADE_CLOSE})} icon children="close"/>}
                />
                <form className="md-toolbar-relative md-grid md-text-container"
                      onSubmit={(e) => {
                          e.preventDefault();
                          dispatch({type: TRADE})
                      }}>
                    <TextField
                        id="selectedDump"
                        label="Selected sites dump"
                        rows={2}
                        maxRows={5}
                        ref="selected"
                        defaultValue={dump}
                        helpText={model.length > 1 ? "Add these sites to yours - press Ctrl-C" : "Add this site to yours - press Ctrl-C"}
                        className="md-cell md-cell--12"
                    />
                    <TextField
                        id="clientDump"
                        label="Your sites dump"
                        rows={2}
                        maxRows={5}
                        ref="dump"
                        onBlur={() => dispatch({type: TRADER_DUMP_CHANGE, value: this.refs.dump.getField().value})}
                        placeholder="http://your-site-url.com|Your Site Title"
                        error={trader_dump_error.length>0}
                        errorText={trader_dump_error}
                        helpText="Put your sites dump here"
                        className="md-cell md-cell--12"
                    />
                    {have_result ?
                        (
                            <div className="md-cell md-cell--12">
                                <h4 key="results-caption">Results:</h4>
                                <TextField
                                    id="Results-dump"
                                    rows={2}
                                    maxRows={8}
                                    value={results}
                                    onChange={() => false}
                                    helpText="Trade add results are here"
                                />
                            </div>
                        )
                        : ''}
                    {adding ? <CircularProgress key="progress" id="add-progress"/> : ''}
                    <footer className="md-dialog-footer md-dialog-footer--inline md-cell md-cell--12">
                        {!have_result ? <Button flat secondary onClick={this._closeDialog} label="Cancel"/> : ''}
                        {<Button flat primary onClick={have_result ? this._closeDialog : this.doTrade}
                                 label={have_result ? 'Close': 'Do trade'}/>}
                    </footer>
                </form>
            </Dialog>
        )
    }
}
function mapStateToProps(state) {
    return {
        visible: state.app.trade_visible,
        model: state.app.trade_model,
        trade_results: state.app.trade_results,
        have_result: state.app.trade_results.length,
        trader_dump: state.app.trader_dump,
        adding: state.app.adding_queue.length > 0,
        trader_dump_error: state.app.trader_dump_error
    }
}

export default connect(mapStateToProps)(TradeDialog);
import {put} from 'redux-saga/effects'
import 'whatwg-fetch'

import {
    AUTH,
    AUTH_OK,
    AUTH_ERROR,
    LOGIN_ERROR,
    LOGIN_OK,
    PREPARE_SITES
} from '../constants/actiontypes'

export function *auth() {
    try {
        const response = yield fetch('/api/auth', {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: "include"
        });
        if (!response.ok) {
            throw Error(response.statusText)
        }
        const json = yield response.json();
        if (json.success) {
            yield put({type: PREPARE_SITES, sites: json.value.sites});
            yield put({type: AUTH_OK, user: json.value.user});
        } else {
            throw Error('not authenticated');
        }
    } catch (err) {
        console.log(err);
        yield put({type: AUTH_ERROR, error: err});
    }
}

export function *login(action) {
    try {
        let response = yield fetch('/api/login', {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
            body: JSON.stringify({_id: action.invite_code})
        });
        if (!response.ok) {
            throw Error(response.statusText)
        }
        let json = yield response.json();
        if (json.success) {
            yield put({type: LOGIN_OK});
            yield put({type: AUTH});
        } else
            yield put({type: LOGIN_ERROR, error: json});
    } catch (err) {
        yield put({type: LOGIN_ERROR, error: err});
        console.log(err);
    }
}

export function *logout() {
    try {
        let response = yield fetch('/api/logout', {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: "include"
        });
        if (!response.ok) {
            throw Error(response.statusText)
        }
        let json = yield response.json();
        if (json.success) {
            yield put({type: AUTH});
        } else
            throw Error("can't logout");
    } catch (err) {
        console.log(err);
    }
}

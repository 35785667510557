import {put} from 'redux-saga/effects'
import 'whatwg-fetch'

import {PREPARE_WEBMASTERS, ADD_TOAST, FETCH_WEBMASTERS_DONE} from '../constants/actiontypes'

export function *fetch_trades() {
    try {
        const response = yield fetch('/api/users', {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: "include"
        });
        if (!response.ok) {
            throw Error(response.statusText)
        }
        const json = yield response.json();
        if(json instanceof Array) {
            for(let i=0; i<json.length;i++) {
                yield put({type: ADD_TOAST, toast: {text: json[i].error}});
            }
            return;
        }
        if (json.success) {
            yield put({type: FETCH_WEBMASTERS_DONE});
            yield put({type: PREPARE_WEBMASTERS, trades: json.value.items});
        } else {
            throw Error('Unexpected error');
        }
    } catch (err) {
        console.log(err);
        yield put({type: ADD_TOAST, toast: {text: err.message}});
    }
}